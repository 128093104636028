import { Box, useColorModeValue, Stack, Container, Heading, Text } from "@chakra-ui/react";

export default function PrivacyPolicy() {

  return (
    <Box bg={useColorModeValue('gray.50', 'gray.700')}>
        <Container maxW={'7xl'} p={{base:2, md:5, lg:16}} as={Stack} >
            <Stack spacing={0} align={'center'} mt={{base:79,md:50,lg:50}} mb={10}>
                <Heading>POLITIQUE DE CONFIDENTIALITÉ</Heading>
            </Stack>
            <Text mb={4}>
        Dernière mise à jour : 15/12/2024
      </Text>

      <Text mb={4}>
        Bienvenue sur www.flitoxnuisible.fr , Nous nous engageons à protéger votre vie privée et à respecter les lois applicables en matière de protection des données. Cette Politique de Confidentialité décrit les informations que nous collectons, comment nous les utilisons, et les choix dont vous disposez concernant ces informations. En accédant au Site, vous acceptez les pratiques décrites dans cette Politique de Confidentialité.
      </Text>

      <Heading as="h2" size="lg" mb={4}>
        1. Les Informations que Nous Collectons
      </Heading>
      <Text mb={4}>
        <strong>1.1. Informations Personnelles</strong><br />
        Nous pouvons collecter des informations personnelles identifiables, telles que votre nom, adresse e-mail, et autres informations que vous fournissez volontairement lors de l'inscription, de la création de compte, de la participation à des enquêtes, ou de toute autre interaction avec le Site.
      </Text>
      <Text mb={4}>
        <strong>1.2. Informations Non Personnelles</strong><br />
        Nous pouvons également collecter des informations non personnelles, telles que des données agrégées et anonymisées sur l'utilisation du Site. Ces informations ne permettent pas votre identification personnelle.
      </Text>

      <Heading as="h2" size="lg" mb={4}>
        2. Comment Nous Utilisons Vos Informations
      </Heading>
      <Text mb={4}>
        Nous utilisons les informations collectées pour :
        - Personnaliser votre expérience sur le Site.
        - Fournir des services et des fonctionnalités demandés.
        - Améliorer la qualité de nos services.
        - Vous informer sur les mises à jour, les offres spéciales et d'autres informations pertinentes.
        - Répondre à vos demandes de support ou de service à la clientèle.
      </Text>

      <Heading as="h2" size="lg" mb={4}>
        3. Partage d'Informations
      </Heading>
      <Text mb={4}>
        Nous ne vendons, n'échangeons ni ne louons vos informations personnelles à des tiers sans votre consentement, sauf dans les cas prévus par la loi.
      </Text>

      <Heading as="h2" size="lg" mb={4}>
        4. Sécurité
      </Heading>
      <Text mb={4}>
        Nous mettons en place des mesures de sécurité pour protéger vos informations contre tout accès non autorisé, divulgation, altération ou destruction.
      </Text>

      <Heading as="h2" size="lg" mb={4}>
        5. Cookies
      </Heading>
      <Text mb={4}>
        Le Site peut utiliser des cookies pour améliorer votre expérience. Vous pouvez configurer votre navigateur pour refuser tous les cookies ou pour vous avertir lorsqu'un cookie est envoyé.
      </Text>

      <Heading as="h2" size="lg" mb={4}>
        6. Modifications de la Politique de Confidentialité
      </Heading>
      <Text mb={4}>
        Nous nous réservons le droit de modifier cette Politique de Confidentialité à tout moment. Les modifications seront effectives dès leur publication sur le Site.
      </Text>

        <Heading as="h2" size="lg" mb={4}>
          7. Contact
        </Heading>
        <Text mb={4}>
          Si vous avez des questions concernant cette Politique de Confidentialité, veuillez nous contacter à contact@flitoxnuisible.fr.
        </Text>

        </Container>
    </Box>
  )
}