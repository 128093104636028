import React from "react";
import ReactDOM from "react-dom";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme"; // Import your theme
import { ColorModeScript } from "@chakra-ui/react";
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from "react-dom/client";

import App from "./App";

const rootElement = document.getElementById("root");

// Check if rootElement exists before calling createRoot
if (rootElement) {
  const root = createRoot(rootElement);

  root.render(
    <ChakraProvider theme={theme}>
      <React.StrictMode>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.StrictMode>
    </ChakraProvider>,
  );
} else {
  console.error("Root element with id 'root' not found");
}
