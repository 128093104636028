import { ChakraProvider, Box } from "@chakra-ui/react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import HomePage from "./pages/HomePage";
import ServicesPage from "./pages/ServicesPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage"
import LegalNoticePage from "./pages/LegalNoticePage"
import ErrorPage from "./pages/ErrorPage"
import WaspsPage from "./pages/WaspsPage";
import CockroachesPage from "./pages/CockroachesPage";
import SpidersPage from "./pages/SpidersPage";
import RatsPage from "./pages/RatsPage";
import BedBugPage from "./pages/BedBugPage";
import { Routes, Route } from 'react-router-dom';

function App() {
  return (
    <ChakraProvider>
      <Box>
        <Navbar />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path='*' element={<ErrorPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/legal-notice" element={<LegalNoticePage />} />
            <Route path="/rats-et-souris" element={<RatsPage />} />
            <Route path="/cafards" element={<CockroachesPage />} />
            <Route path="/punaises-de-lit" element={<BedBugPage />} />
            <Route path="/guepes-et-frelons" element={<WaspsPage />} />
            <Route path="/araignees" element={<SpidersPage />} />
          </Routes>
        <Footer/>
      </Box>
    </ChakraProvider>
  );
}

export default App;