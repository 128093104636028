import { Box, useColorModeValue, Stack, Container, Heading, Text } from "@chakra-ui/react";

export default function LegalNotice() {

  return (
    <Box bg={useColorModeValue('gray.50', 'gray.700')}>
        <Container maxW={'7xl'} p={{base:2, md:5, lg:16}} as={Stack} >
            <Stack spacing={0} align={'center'} mt={{base:79,md:50,lg:50}} mb={10}>
                <Heading>MENTION LÉGALES</Heading>
            </Stack>
            <Text>
              Nom de la société : Fli Tox Anti Nuisible 
            </Text>
            <Text>
              Numéro de Siret : 937867570
            </Text>
            <Text>
              Nom du responsable : Fares Labbassi 
            </Text>
            <Text>
              Adresse de la société : 5 Pl. Maréchal Lyautey, 69006 Lyon
            </Text>
            <Text>
              Adresse du siège social de la société : 5 Pl. Maréchal Lyautey, 69006 Lyon
            </Text>
            <Text>
              Conditions Générales :
            </Text>
            <Text>
              1. Les Présentes Conditions Générales de Vente («CGV») ont pour objet de préciser les modalités et conditions d’exécution des prestations de services («Prestations») fournies par Fli  nuisible à ses clients.
            </Text>
            <Text>
            2. Pour l’application des présentes CGV, comme pour celle des conditions particulières qui en sont indivisibles, les termes suivants, auront la signification suivante:
            </Text>
            <Text>

            </Text>
              Partie(s) : Fli Tox anti nuisible et Client
            <Text>
            Client(s) : Personne physique ou morale liée par le Contrat avec Fli Tox  ou qui fait appel à Fli Tox pour une prestation ponctuelle.
            </Text>
            <Text>
            Contrat(s) : Ensemble des documents contractuels régissant les droits et les obligations respectifs des Parties dans le cadre des Prestations fournies par fli Tox  à son Client.
            </Text>
            <Text>
            Prestation(s) : Ensemble des prestations réalisées par Nuisiprotect pour le Client.
            </Text>
            <Text>
            3. Les présentes CGV sont systématiquement remises au Client. Toute condition contraire posée par le Client est, à défaut d’acceptation expresse par Fli Tox , inopposable à cette dernière. Fli Tox se réserve la possibilité de modifier les présentes, à tout moment et les CGV applicables alors sont celles étant en vigueur à la date du paiement de la commande. Le Client déclare avoir pris connaissance de l’ensemble des présentes CGV et les accepter sans restriction ni réserve. La signature du Contrat ou Commande implique son adhésion entière à l’ensemble des documents contractuels. Le Contrat entre en vigueur à sa date de signature par les Parties pour une durée de 36 mois. A l’issue de sa durée initiale le Contrat est reconduit pour des périodes successives de 36 mois par tacite reconduction sauf dénonciation par lettre recommandée avec avis de réception, moyennant le respect d’un préavis de 3 mois. En cas de changement de Prestataire, le Client s’engage à informer  par courrier recommandé de toute procédure de remise en concurrence, un mois au moins avant le début de ladite procédure.
            </Text>
            <Text>
            4. Le Client s’engage à apporter toute sa collaboration à Fli Tox afin de définir au mieux ses besoins, et à mettre à sa disposition et à ses frais tout moyen nécessaire lui incombant. A défaut, Fli Tox est relevé de l’obligation de réaliser les Prestations. Le Client s’engage à être présent au rendez-vous fixé, faute de quoi, la prestation lui sera entièrement facturée par Fli Tox et due par le Client aux conditions de l’art.7
            </Text>
            <Text>
            5. Les Prestations sont garanties 6 mois après la fin du traitement sous conditions du respect du protocole 3D fourni au client. Le Client s’engage à signaler à Fli Tox toute disposition, risque particulier, ou éléments, à prendre en compte et inhérents à son activité ou à la configuration des lieux et objets des Prestations. A défaut, Fli Tox en décline toute responsabilité. Fli Tox ne pourra être tenu responsable des retards dans l’exécution de la Prestation.
            </Text>
            <Text>
            6. Les prix sont stipulés hors taxes et sans escompte. Leur nature et leur montant sont ceux figurant dans le Contrat. Le prix est basé sur les conditions économiques connues à la date de signature du Contrat. En cas de modification de la réglementation économique, discale ou sociale ou pour quelque motif que ce soit, Nuisiprotect se réserve le droit de répercuter à tout moment les incidences de ce changement sur les prix de la Prestation.
            </Text>
            <Text>
            7. Les règlements du Client interviendront selon les conditions de paiement spécifiées sur la facture. Si le Client ne remplit pas son obligation de paiement dans le délai de paiement imparti ou renonce à invoquer une objection fondée dans un délais de 15 jours calendaires date de facture il est en demeure dès l’échéance dudit délai sans rappel. A défaut de paiement de chaque facture à son échéance, une pénalité de 10% sera appliquée sur le total des sommes dues au taux égal à 10 fois le taux d’intérêt légal à partir de l’expiration du 2ème mois qui suit la réception de la Prestation avec un minimum de 50€ à titre d’indemnité conventionnelle. Tout retard de paiement entraîne la déchéance du Contrat, la totalité des sommes dues devenant exigibles de suite.
            </Text>
            <Text>
            8. Fli Tox est responsable des seuls dommages qu’il pourrait occasionner lors de l’exécution de ses obligations au titre du Contrat. Dans la mesure où le client subit un dommage du fait d’une exécution fautive de Fli Tox ce dernier est tenu à la réparation du préjudice dans la limite des montants prévus à son contrat d’assurance. Fli Tox ne sera pas tenu responsable de tout dommage indirect ou immatériel.
            </Text>
            <Text>
            9. Les Prestations peuvent faire l’objet d’une réception contradictoire. En cas de défaut constaté et avéré au plus tard dans un délais de 15 jours après la réception des travaux.Fli Tox s’engage à y remédier sous 72 heures hors dimanches et jours fériés. La responsabilité de Fli Tox est expressément limitée à la reprise des Prestations mises en cause. Le Client est réputé avoir tacitement réceptionné les prestations par signature du rapport d’intervention soumis par Fli Tox ou par défaut par validation de la facture à l’issue de l’intervention.
            </Text>
            <Text>
            10. Les parties reconnaissent que le Contrat et les informations qu’il contient revêtent d’un caractère confidentiel et s’engagent à n’en révéler la teneur à aucune personne autre que celles nécessaires à son exécution.
            </Text>
            <Text>
            11. En cas de survenance d’un événement extérieur totalement ou partiellement les obligations lui incombant, l’une des parties ne pourra invoquer les cas de force majeur qu’à la condition d’en notifier sous 48 heures la survenance par lettre recommandée avec avis de réception, adressée à l’autre Partie.
            </Text>
            <Text>
            12. En cas de manquement de l’une des parties à ses obligations contractuelles, l’autre partie pourra résilier le contrat après mise en demeure de s’y conformer, adressée par lettre recommandée avec demande d’avis de réception à la partie défaillante, restée sans effet dans un délais de 15 jours calendaires, sans préjudice de tous dommages et intérêts auxquels la Partie non défaillante pourrait par ailleurs prétendre. La partie qui résilierait le Contrat sans motif valable avant son échéance normale, serait redevable envers l’autre d’une indemnité de résiliation. En cas de résiliation du fait du Client, l’indemnité de résiliation correspondra aux frais (engagés par Fli Tox au jour de la résiliation pour la bonne exécution du Contrat.)
            </Text>
            <Text>
            13. Les Parties s’efforceront de régler à l’amiable tous les différends relatifs à l’interprétation et à l’exécution du Contrat. Pour les cas où les Parties, dans un délai raisonnable, ne parviendraient pas à un accord, conformément aux dispositions de l’article ci-dessus, il est expressément convenu que les tribunaux compétents seront ceux du siège Fli Tox. Le tribunal ainsi désigné statuera en droit Français.
            </Text>
            <Text>
            Tous nos produits sont homologués par le ministère de l’Environnement.
            </Text>
            <Text>
            EDITEUR DE LA PUBLICATION : FLI-TOX
            </Text>
            <Text>
            DIRECTEUR DE LA PUBLICATION : FLI-TOX
            </Text>
            <Text>
            RESPONSABLE DE LA REDACTION : FLI-TOX
            </Text>
        </Container>
    </Box>
  )
}